/**
 *
 * Typography
 *
 */

@use 'vars';
@use 'mixins' as *;

.regular {
  font-weight: 400;
}

.semi-bold {
  font-weight: 600;
}

strong,
.bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

h1,
.h1 {
  font-size: rem(34px);
  margin-bottom: 1em;
  color: var(--color-white);
  @extend .bold;

  @include bp(medium) {
    font-size: rem(40px);
  }
}

h2,
.h2 {
  font-size: rem(28px);
  margin-bottom: 1em;
  color: var(--color-primary);
  @extend .semi-bold;

  @include bp(medium) {
    font-size: rem(34px);
  }
}

h3,
.h3 {
  font-size: rem(24px);
  margin-bottom: 1em;
  color: var(--color-primary);
  @extend .semi-bold;

  @include bp(medium) {
    font-size: rem(26px);
  }
}

h4,
.h4 {
  font-size: rem(20px);
  margin-bottom: 1em;
  color: var(--color-primary);
  @extend .semi-bold;
}

p {
  font-size: rem(16px);
  margin-bottom: 1.5em;

  &.large {
    font-size: 2rem;
  }
}

.caps {
  font-size: rem(14px);
  @extend .uppercase;
  @extend .bold;
}

.text-center {
  text-align: center;
}
