@use '../../../assets/styles/mixins' as *;

input[type='text'],
input[type='email'],
input[type='password'],
input[type='number'],
input[type='date'],
input[type='tel'] {
  appearance: none;
  border: rem(2px) solid var(--color-primary);
  border-radius: var(--border-radius);
  padding: 0.5rem 1rem;
  color: var(--color-black);
  font-family: var(--font-body);
  font-size: rem(14px);
  line-height: rem(24px);
  font-weight: 400;
  text-align: left;
  width: 100%;
  height: auto;
  background: var(--color-white);

  &::placeholder {
    opacity: 1;
    color: var(--color-secondary);
  }

  &:focus {
    border: rem(2px) solid var(--color-primary-light);
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    background: var(--color-secondary-light);
    opacity: 0.5;

    &:hover,
    &:focus {
      box-shadow: none;
    }
  }

  .-has-error & {
    background: var(--color-error-light);

    &:focus {
      border: rem(2px) solid var(--color-primary);
    }
  }

  &:hover,
  &:focus {
    transition: box-shadow var(--transition-duration) var(--easing);
    box-shadow: var(--box-shadow);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type='number'] {
  appearance: textfield;
}
