@forward 'input/input';
@forward 'checkbox-radio/checkbox-radio';
@forward 'textarea/textarea';
@forward 'select/select';
@use '../../assets/styles/mixins' as *;

$className: 'form-item';

.#{$className} {
  margin-bottom: 2rem;

  label {
    display: block;
    color: var(--color-white);
    font-weight: 700;
    text-transform: uppercase;
    font-size: rem(14px);
    line-height: rem(20px);
    text-align: left;
    padding-left: 1rem;
    margin-bottom: 0.5rem;

    span {
      color: var(--color-white);
      display: inline-block;
      font-weight: 700;
    }
  }

  &.-has-icon {
    div {
      position: relative;
    }
  }

  &.-has-error {
    label {
      &::before {
        border-color: var(--color-error);
      }

      &:hover {
        &::before {
          border-color: var(--color-error);
          background-color: var(--color-white);
        }
      }
    }

    .#{$className}__icon {
      .icon {
        color: var(--color-error);
      }
    }
  }

  &__icon {
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 1px;
    min-width: 1rem;
    background: var(--color-secondary-light);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.875rem;
    border-radius: 0.5rem;
    height: calc(100% - 4px);
    cursor: pointer;

    .icon {
      font-size: 1rem;
      color: var(--color-black) !important;
    }
  }

  &__note,
  &__error {
    display: block;
    padding: 0.5rem 0 0 0.5rem;
    font-size: 0.875rem;
    line-height: 1rem;

    &.--with-padding {
      padding-left: 2.5rem;
    }
  }

  &__note {
    color: var(--color-secondary-dark);
  }

  &__error {
    color: var(--color-black);
    font-weight: 700;
    font-size: rem(12px);
    line-height: rem(15px);
    text-align: left;
    padding-left: 1rem;
  }

  &--textarea {
    label {
      margin-bottom: 1rem;
    }
  }

  &--select {
    margin-bottom: 0;
  }
}

@include bp(small) {
  .input-group {
    display: flex;
    gap: rem(14px);

    .group-input-small {
      width: rem(100px);
    }

    .group-input-big {
      flex: 1;
    }
  }
}

// Clear placeholders on focus
[placeholder]::-webkit-input-placeholder {
  transition: opacity var(--transition-duration) var(--easing);
  opacity: 1;
}

[placeholder]:focus::-webkit-input-placeholder {
  opacity: 0;
}

section.form {
  max-width: rem(1120px);
  margin: 0 auto;
  background-color: var(--color-primary);
  padding: 2rem;
  text-align: center;
  border-radius: var(--border-radius);
  color: var(--color-white);
  margin-bottom: 3rem;

  @include bp(large) {
    padding: 3rem 9rem;
  }

  form {
    max-width: rem(554px);
    margin: 3rem auto 0;

    --columns: 1;
    --gap: 0 2rem;

    @include bp(medium) {
      --columns: 2;
    }

    & > label {
      display: block;
      color: var(--color-white);
      font-weight: 700;
      text-transform: uppercase;
      font-size: rem(14px);
      line-height: rem(20px);
      text-align: left;
      padding-left: 1rem;
      margin-bottom: 0.5rem;
    }

    #checkbox-group-error {
      margin-bottom: rem(40px);
    }

    label[for='newsletter'] {
      text-transform: none;
      font-size: rem(12px);
      line-height: 1rem;
      font-weight: 400;
      font-style: italic;
    }
  }

  small {
    font-style: italic;
    margin-bottom: 1rem;
    display: block;
  }
}
