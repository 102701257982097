@use '../../../assets/styles/mixins' as *;

textarea {
  appearance: none;
  font-family: var(--font-body);
  border: rem(1px) solid var(--color-secondary-light);
  border-radius: var(--border-radius);
  padding: 0.75rem 1rem;
  color: var(--color-black);
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  height: 6rem;
  min-height: 6rem;
  background: var(--color-white);
  overflow: auto;

  &::placeholder {
    opacity: 1;
    color: var(--color-secondary-light);
  }

  &:focus {
    border: rem(1px) solid var(--color-primary);
    outline: none;
  }

  &:disabled {
    cursor: default;
    background: var(--color-secondary-light);
  }

  .-has-error & {
    // border: rem(1px) solid var(--color-error);
    background: var(--color-error-light);

    &:focus {
      border-color: var(--color-primary);
    }
  }

  &:hover,
  &:focus {
    transition: box-shadow var(--transition-duration) var(--easing);
    box-shadow: var(--box-shadow);
  }
}
