@use '../../assets/styles/mixins' as *;

$className: 'button';

.#{$className} {
  appearance: none;
  cursor: pointer;
  margin: 0 auto 1.5rem;
  text-align: center;
  backface-visibility: hidden;
  border-radius: rem(30px);
  border: rem(1px) solid var(--color-primary);
  padding: 0.5rem 2rem;
  background-color: var(--color-primary);
  color: var(--color-white);
  text-decoration: none;
  transition: all var(--transition-duration) var(--easing);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  box-shadow: var(--box-shadow);
  font-size: rem(14px);
  line-height: rem(20px);
  font-weight: 700;

  &:hover {
    color: var(--color-primary);
    background-color: var(--color-white);
    border-color: var(--color-primary);
  }

  &[disabled] {
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
    cursor: default;

    &:hover {
      color: var(--color-white);
    }
  }

  &--secondary {
    background-color: var(--color-accent);
    color: var(--color-white);
    border-color: var(--color-accent);

    &:hover {
      background-color: var(--color-white);
      border-color: var(--color-accent);
      color: var(--color-accent);
    }

    &[disabled] {
      background-color: var(--color-secondary);
      border-color: var(--color-secondary);
      cursor: default;

      &:hover {
        color: var(--color-white);
      }
    }
  }
}
