@use '../../assets/styles/mixins' as *;

$className: 'swiper';

.#{$className} {
  padding-bottom: 5rem;

  @include bp(medium) {
    padding-bottom: 3.5rem;
  }

  &-slide {
    height: auto;
  }

  &-controls {
    position: absolute;
    bottom: 2.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    @include bp(medium) {
      flex-direction: row;
      bottom: rem(10px);
    }

    .#{$className} {
      &-pagination {
        position: unset;
      }

      &-more {
        position: absolute;
        z-index: 11;
        left: 0;
        bottom: rem(-40px);

        @include bp(medium) {
          left: unset;
          bottom: 0;
        }
      }
    }
  }

  &-pagination-bullet {
    width: 0.5rem;
    height: 0.5rem;
    margin: 0 0.5rem !important;
    background: var(--color-primary-light);
    opacity: 1;

    &-active {
      background: var(--color-primary);
    }
  }

  &-button-next,
  &-button-prev {
    top: calc(50% - 1.5rem);
    background: var(--color-white);
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    border-radius: 50px;
    border: rem(2px) solid var(--color-secondary-light);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--color-black);
    transition: background-color var(--transition-duration) var(--easing);

    &:hover {
      background-color: var(--color-primary);
      color: var(--color-white);
    }

    .icon {
      font-size: rem(18px);
    }

    &.swiper-button-disabled {
      display: none;
    }
  }

  &-button-next {
    right: rem(5px);
  }

  &-button-prev {
    left: rem(5px);

    .icon {
      transform: rotate(-180deg);
    }
  }

  &.-auto-width {
    .#{$className}-slide {
      width: auto;
      max-width: 400px;
      margin-right: 1.5rem;
    }
  }

  &.-with-gradient {
    &::before {
      content: '';
      width: 3rem;
      height: 100%;
      background: url('../images/white-gradient.png') repeat-y;
      position: absolute;
      right: 0;
      z-index: 9;
      opacity: 1;
      transition: opacity var(--transition-duration) var(--easing);
    }

    &.end {
      &::before {
        opacity: 0;
      }
    }
  }
}
