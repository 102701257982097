@use 'mixins' as *;

$className: 'styleguide';

body.#{$className} {
  // stylguide styles
  main {
    padding-bottom: 2rem;
  }

  .logo {
    img {
      width: rem(250px);
    }
  }

  .#{$className} {
    &-title {
      color: var(--color-secondary);
    }

    &-icons {
      --col-width: 10rem;

      &__icon {
        padding: 1rem;
        border: rem(1px) solid var(--color-secondary-light);
        border-radius: var(--border-radius);
        text-align: center;

        p {
          margin: 1rem 0 0;
        }
      }
    }

    &-colors {
      --col-width: 10rem;

      .color {
        padding: 1rem;
        border-radius: var(--border-radius);
        text-align: center;

        p {
          margin-bottom: 0;

          &:last-child {
            font-weight: 600;
            text-transform: uppercase;
          }
        }

        &.-white-text {
          color: var(--color-white);
        }

        &.-with-border {
          border: rem(1px) solid rgba(0 0 0 / 0.08);
        }
      }
    }

    &-forms {
      --columns: 1;
      @extend .margin-bottom-md;

      @include bp(medium) {
        --columns: 2;
        --gap: 2rem;
      }
    }

    &-single-image-slider {
      max-width: rem(400px);
      transition: max-width var(--transition-duration) var(--easing);

      @include bp(medium) {
        max-width: rem(600px);
      }
    }

    &-height {
      max-height: 20rem;
    }

    &-tooltips {
      --col-width: 8rem;

      .square {
        border-radius: var(--border-radius);
        padding: 1rem;
        font-size: 600;
        color: var(--color-white);
        text-align: center;

        p {
          margin-bottom: 0;
        }

        &.-blue {
          background-color: var(--color-primary);
        }

        &.-red {
          background-color: var(--color-error);
        }

        &.-green {
          background-color: var(--color-accent);
        }

        &.-orange {
          background-color: var(--color-secondary);
        }
      }
    }

    &-flex {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
