@use 'choices.js/src/styles/choices';
@use '../../../assets/styles/mixins' as *;

.choices {
  margin-bottom: 0.5rem;

  &::after {
    background-image: url('../images/arrow-down.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 36%;
    background-color: var(--color-secondary-light);
    border: none !important;
    width: 2.75rem !important;
    aspect-ratio: 1;
    right: rem(2px) !important;
    top: rem(5px) !important;
    border-radius: var(--border-radius);
  }

  &__inner {
    border-radius: var(--border-radius);
    background-color: var(--color-white);
    min-height: 3rem;
    border-color: var(--color-secondary);
  }

  &__placeholder {
    font-size: 1rem;
    color: var(--color-secondary-light);
  }

  &[data-type*='select-one'] {
    &.is-open {
      border-radius: var(--border-radius);

      &::after {
        margin-top: rem(-3px);
      }
    }
  }

  .choices__list--single {
    .choices__item--selectable {
      font-size: 1rem;
      color: var(--color-black);
    }
  }

  &__list {
    .choices__item--selectable {
      font-size: rem(18px);
      color: var(--color-secondary-dark);
    }
  }

  &.is-focused {
    .choices__inner {
      border-color: var(--color-secondary);
    }
  }

  &.is-open {
    box-shadow: var(--box-shadow);

    .choices__inner {
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      border-color: var(--color-white);
      position: relative;
      z-index: 2;
    }

    .choices__list--dropdown.is-active {
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      border-color: var(--color-white);
      box-shadow: var(--box-shadow-down);
    }

    &::after {
      background-color: transparent;
      z-index: 3;
      transform: rotate(180deg);
      transition: transform var(--transition-duration) var(--easing);
    }
  }

  .choices__list--dropdown {
    .choices__item--selectable {
      &.is-highlighted {
        color: var(--color-primary);
        background-color: var(--color-primary-light);
      }

      &.is-selected {
        font-weight: 600;
      }
    }
  }

  &.is-disabled {
    .choices__inner {
      background-color: var(--color-secondary-light);
      border-color: var(--color-secondary-light);
    }
  }
}

.form-item.-has-error {
  .choices {
    &__inner {
      border-color: var(--color-error);
    }
  }
}
