@use 'mixins' as *;

a {
  color: var(--color-black);
  transition: all var(--transition-duration) var(--easing);
  text-decoration: none;

  &:hover {
    color: var(--color-primary);
  }

  &.with-icon {
    text-decoration: none;
    padding: 0.5rem;
    display: inline-flex;
    align-items: center;
    border-radius: var(--border-radius);

    span {
      text-decoration: underline;
      transition: all 0.25s ease;
      font-weight: 500;
    }

    .icon {
      margin-right: 1rem;
      color: var(--color-black);
      font-size: 1rem;
    }

    &:hover {
      background-color: var(--color-primary-light);
      border-radius: var(--border-radius);
      color: var(--color-black);

      span {
        text-decoration: none;
      }
    }
  }

  &.--round {
    width: 2.5rem;
    aspect-ratio: 1;
    padding: 0;
    border-radius: 50%;
    box-shadow: var(--box-shadow);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--color-black);
    transition: background-color 0.25s ease;
    border: rem(3px) solid var(--color-white);

    .icon {
      margin-right: 0;
      font-size: 1rem;
    }

    &:hover {
      border-radius: 50%;
      background-color: var(--color-primary-light);
      border: rem(3px) solid var(--color-white);

      .icon {
        color: var(--color-black);
      }
    }
  }

  &.form-link {
    color: var(--color-white);
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .general & {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.more {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  display: inline-flex;
  align-items: center;
  padding-right: 2rem;
  position: relative;
  color: var(--color-black);
  text-decoration: none;

  .icon {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translate(0, -50%);
    transition: all 0.25s ease;
    width: 1rem;
  }

  &:hover {
    .icon {
      right: 0;
    }
  }
}
