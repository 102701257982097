@use 'vars';
@use 'mixins' as *;

body {
  font-family: var(--font-body);
  display: flex;
  flex-direction: column;
  line-height: 1.4;
  color: var(--color-secondary-dark);
  overflow-x: hidden;

  @include bp(small) {
    overflow-x: visible;
  }
}

main {
  flex-grow: 1;

  &.greetings-container {
    display: grid;
    place-items: center;
    background: var(--color-secondary-dark);
    padding: 1rem;
  }
}

.container {
  max-width: var(--container-width);
  margin: 0 auto;
  padding: 0 1.5rem;
  width: 100%;
}

.overlay {
  background: var(--color-black);
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 2;
}

.about {
  --gap: 1rem;
  --columns: 1;

  @include bp(medium) {
    --columns: 2;
    --gap: 0 3rem;

    margin: 0 auto;
    max-width: 1120px;
    grid-template-areas:
      'title image'
      'desc image';

    &__title {
      grid-area: title;
      align-self: end;
    }

    &__image {
      grid-area: image;
    }

    &__desc {
      grid-area: desc;
    }
  }
}

.companies {
  margin-bottom: 4rem;

  @include bp(medium) {
    margin-bottom: 5rem;
  }
}

.testemonials {
  &__header {
    text-align: center;
    max-width: rem(740px);
    margin: 0 auto;
  }

  .swiper {
    margin-bottom: 2rem;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    content: none;
  }

  .swiper-button-next {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    margin-top: 0;
    display: flex !important;
    transform: rotate(-90deg);
    border: 0;
    width: 2rem;
    height: 2rem;

    .icon {
      color: var(--color-primary);
      font-size: 2rem;
      width: 2rem;
      height: auto;
    }

    &:hover {
      background: transparent;
    }
  }

  .swiper-button-prev {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    margin-top: 0;
    display: flex !important;
    transform: rotate(-90deg);
    border: 0;
    width: 2rem;
    height: 2rem;

    .icon {
      color: var(--color-primary);
      font-size: 2rem;
      width: 2rem;
      height: auto;
    }

    &:hover {
      background: transparent;
    }
  }

  .swiper-controls {
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    bottom: 0;
    position: relative;
    margin-top: 2rem;
  }

  .swiper-pagination {
    width: rem(160px);
    background-color: rgb(0 161 129 / 0.2);
    height: 1px;

    .swiper-pagination-progressbar-fill {
      background: var(--color-primary);
      height: 3px;
      top: -1px;
    }
  }

  @include bp(medium) {
    margin-bottom: 5rem;

    &__header {
      margin: 0 auto 3rem;
    }

    .swiper-button-next {
      width: rem(44px);
      height: rem(44px);

      .icon {
        font-size: rem(44px);
        width: rem(44px);
      }
    }

    .swiper-button-prev {
      width: rem(44px);
      height: rem(44px);

      .icon {
        font-size: rem(44px);
        width: rem(44px);
      }
    }

    .swiper-controls {
      bottom: -1rem;
    }
  }
}

.testemonial-single {
  background: var(--color-secondary-light);
  border: 1px solid var(--color-primary);
  border-radius: rem(20px);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem 1rem;
  gap: 0.75rem;

  &__quote {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    flex-direction: column;
    padding: 1.5rem 0;

    &__top-icon {
      position: absolute;
      top: 0;
      left: 0;

      .icon {
        width: 1.5rem;
        font-size: 1.5rem;
        color: var(--color-primary-light);
      }
    }

    &__bottom-icon {
      position: absolute;
      bottom: 0;
      right: 0;

      .icon {
        width: 1.5rem;
        font-size: 1.5rem;
        color: var(--color-primary-light);
      }
    }
  }

  &__author {
    color: var(--color-primary);
    text-align: center;

    strong {
      display: block;
      margin-bottom: rem(6px);
    }
  }

  @include bp(medium) {
    padding: 2rem;
  }
}

.hero {
  text-align: center;
  margin-bottom: 3rem;
  position: relative;

  picture {
    @include bp(medium) {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--color-black);
        opacity: 0.4;
        z-index: 1;
      }
    }
  }

  &__image {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    max-width: none;

    @include bp(small) {
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
    }
  }

  &__cta {
    text-align: left;
    background: var(--color-primary);
    border-radius: 0 0 var(--border-radius) var(--border-radius);
    padding: 2rem;
    max-width: rem(930px);
    margin: -3rem auto 0;
    position: relative;
    z-index: 2;

    &--arrow {
      display: none;

      .icon {
        font-size: 4rem;
        width: 4rem;
        color: var(--color-white);
      }
    }

    @include bp(small) {
      margin-top: rem(-96px);
      padding: 1rem 4rem;
    }

    @include bp(medium) {
      display: flex;
      padding: 0;

      &-wrap {
        flex: 1;
        padding: 1rem 4rem;
      }

      &--arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(178px);
        border-left: 1px solid rgb(255 255 255 / 0.2);
      }
    }
  }

  .button.hero__play-video {
    margin: 3rem auto 0;
    animation: glow 2.5s ease-out infinite;

    @include bp(medium) {
      display: none;
    }
  }

  &__play-button {
    background: var(--color-white);
    width: 6rem;
    aspect-ratio: 1;
    border-radius: 50%;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    display: none;
    animation: glow 2.5s ease-out infinite;
    z-index: 3;

    @include bp(medium) {
      display: block;
    }

    .icon {
      color: var(--color-primary);
      font-size: 2.5rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-43%, -50%);
    }

    &:hover {
      .icon {
        color: var(--color-accent);
      }
    }
  }

  @keyframes glow {
    0%,
    100% {
      box-shadow: 1px 0 19px 4px var(--color-accent), inset 0 0 10px rgb(255 255 255 / 0.5);
    }

    50% {
      box-shadow: 0 0 0 0 rgb(255 245 3 / 0), inset 0 0 0 rgb(255 255 255 / 0);
    }
  }
}
