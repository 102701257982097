@use '../../assets/styles/mixins' as *;
@use '../../../node_modules/handorgel/src/scss/style';

$className: 'handorgel';

.#{$className} {
  margin: 2rem 0;
  border: none;

  &__header {
    &__button {
      display: grid;
      grid-template-areas:
        'logo icon'
        'title title';
      grid-template-columns: 1fr 101px;
      padding: 0;
      font-family: var(--font-body);
      border-radius: 20px;
      overflow: hidden;
      border: 1px solid var(--color-primary) !important;
      margin-bottom: 1rem;

      &[aria-expanded='true'] {
        border-bottom: 0 !important;
        border-radius: 20px 20px 0 0;
        margin-bottom: 0;

        .#{$className} {
          &__icon.-open {
            display: none;
          }
        }
      }

      &[aria-expanded='false'] {
        .#{$className}__icon.-close {
          display: none;
        }
      }

      &:hover {
        .#{$className}__icon {
          background-color: var(--color-primary-dark);

          .icon {
            color: var(--color-white);
          }
        }
      }
    }

    &__text {
      display: inline-block;
      grid-area: title;
      background: var(--color-secondary-light);
      padding: 2rem;

      span {
        font-size: 1rem;
        font-weight: 600;
      }
    }

    &__logo {
      grid-area: logo;
      border-right: 1px solid #dbdbdb;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      gap: rem(5px);

      span {
        color: var(--color-black);
      }

      img {
        width: rem(110px);
      }
    }

    &--open,
    &--focus,
    .#{$className}__header__button {
      background-color: transparent !important;
      border: none;
    }
  }

  &__icon {
    font-weight: 400;
    transition: background-color var(--transition-duration) var(--easing);
    line-height: 1;
    grid-area: icon;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    &.-open {
      .icon {
        transform: rotate(180deg);
      }
    }

    .icon {
      width: rem(36px);
      font-size: rem(36px);
      height: auto;
      aspect-ratio: 1;
      color: var(--color-primary);
    }
  }

  &__content {
    border: none;
    border-radius: 0 0 20px 20px;
    overflow: hidden;

    &__inner {
      padding: 2rem 2rem 0.5rem;
      background: var(--color-secondary-light);
    }

    &--opened {
      margin-bottom: 1rem;
      border-right: 1px solid var(--color-primary);
      border-bottom: 1px solid var(--color-primary);
      border-left: 1px solid var(--color-primary);
    }
  }

  @include bp(medium) {
    &__header {
      &__button {
        grid-template-areas: 'logo title icon';
        grid-template-columns: 330px 1fr 170px;
        margin-bottom: 2rem;

        &[aria-expanded='true'] {
          .#{$className} {
            &__header__text {
              border-bottom: 1px solid #dbdbdb;
            }

            &__icon {
              border-bottom: 1px solid #dbdbdb;
            }
          }
        }
      }

      &__text {
        border-right: 1px solid #dbdbdb;
        padding: rem(38px) 3rem;
        height: 100%;

        span {
          font-size: rem(20px);
        }
      }

      &__logo {
        border-right: 0;
        justify-content: center;
        gap: rem(10px);

        img {
          width: rem(195px);
        }

        &.-vita {
          img {
            width: rem(180px);
          }
        }
      }
    }

    &__icon {
      background: var(--color-secondary-light);

      .icon {
        width: rem(42px);
        font-size: rem(42px);
      }
    }

    &__content {
      padding-left: 330px;

      &__inner {
        padding: 2rem 4rem;
      }

      &--opened {
        overflow: hidden;
        margin-bottom: 2rem;
      }
    }
  }
}
