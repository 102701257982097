@use '../../assets/styles/mixins' as *;

.footer {
  --color-footer: #e8e7e7;

  background: var(--color-footer);
  color: var(--color-secondary-dark);
  padding: 2rem 0;

  @include bp(medium) {
    padding: rem(48px) 0 1rem 0;
  }

  &__top {
    --gap: 2rem 0.75rem;

    margin-bottom: 3rem;
    grid-template-areas:
      'logo'
      'sava'
      'pokojninska'
      'infond'
      'vita';

    &--item {
      display: flex;
      flex-direction: column;

      a {
        white-space: nowrap;
        width: fit-content;
      }
    }

    .spacer {
      display: block;
      width: rem(80px);
      height: 1px;
      background: var(--color-primary);
      margin-top: 1.2rem;
      margin-bottom: 1rem;

      @include bp(medium) {
        margin-top: auto;
      }
    }

    .logo {
      grid-area: logo;
      margin-bottom: rem(60px);
    }

    .sava {
      grid-area: sava;
    }

    .pokojninska {
      grid-area: pokojninska;
    }

    .infond {
      grid-area: infond;
    }

    .vita {
      grid-area: vita;
    }

    @include bp(medium) {
      --columns: 6;
      --gap: 3rem;

      grid-template-areas: 'logo logo sava pokojninska infond vita';
    }
  }

  &__bottom {
    color: var(--color-secondary);
    padding-top: 1rem;
    border-top: 1px solid var(--color-primary);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    a {
      color: inherit;

      &:hover {
        color: var(--color-primary);
      }
    }

    .caps {
      margin-top: 1rem;
    }

    @include bp(medium) {
      flex-direction: row;
      align-items: center;
      gap: 2rem;

      .caps {
        margin-top: unset;
        margin-left: auto;
      }
    }
  }
}

.social {
  display: flex;
  gap: 0.5rem;

  &__item {
    width: rem(20px);
    aspect-ratio: 1;
    border-radius: 50%;
    background: var(--color-secondary-dark);
    flex: 0 0 auto;
    position: relative;
    transition: all 0.25s ease;

    .icon {
      color: var(--color-footer);
      width: 0.7rem;
      position: absolute;
      inset: 0;
      margin: auto;
    }

    &:hover {
      background: var(--color-primary);
    }
  }
}
