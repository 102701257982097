/**
 *
 * Mixins
 *
 */

@use 'sass:map';
@use 'sass:math';

// rem and em helpers
@function rem($px) {
  @return math.div($px, 16px) * 1rem;
}

@function em($px) {
  @return math.div($px, 16px) * 1em;
}

// Breakpoints
$breakpoints: (
  small: em(768px),
  medium: em(1024px),
  large: em(1280px),
  xlarge: em(1440px),
);

@mixin bp($name) {
  @if not map.has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map.get($breakpoints, $name)) {
      @content;
    }
  }
}

// Spacings
@mixin spacings {
  @each $space in (padding, margin) {
    .no-#{$space} {
      #{$space}: 0 !important;
    }

    .no-#{$space}-bottom {
      #{$space}-bottom: 0 !important;
    }

    .no-#{$space}-top {
      #{$space}-top: 0 !important;
    }
  }

  @each $direction in (top, bottom) {
    .padding-#{$direction}-tiny {
      padding-#{$direction}: var(--spacing-tiny) !important;
    }

    .padding-#{$direction}-sm {
      padding-#{$direction}: var(--spacing-sm) !important;
    }

    .padding-#{$direction}-md {
      padding-#{$direction}: var(--spacing-md) !important;
    }

    .padding-#{$direction}-lg {
      padding-#{$direction}: var(--spacing-lg) !important;
    }

    .padding-#{$direction}-xl {
      padding-#{$direction}: var(--spacing-xl) !important;
    }
  }

  @each $direction in (top, bottom) {
    .margin-#{$direction}-tiny {
      margin-#{$direction}: var(--spacing-tiny) !important;
    }

    .margin-#{$direction}-sm {
      margin-#{$direction}: var(--spacing-sm) !important;
    }

    .margin-#{$direction}-md {
      margin-#{$direction}: var(--spacing-md) !important;
    }

    .margin-#{$direction}-lg {
      margin-#{$direction}: var(--spacing-lg) !important;
    }

    .margin-#{$direction}-xl {
      margin-#{$direction}: var(--spacing-xl) !important;
    }
  }
}

@include spacings;
