@use '../../assets/styles/mixins' as *;

$className: 'header';

.#{$className} {
  height: rem(80px);
  display: flex;
  align-items: center;

  .logo {
    display: inline-block;

    img {
      width: rem(123px);
    }
  }

  @include bp(medium) {
    height: rem(110px);

    .logo {
      img {
        width: rem(131px);
      }
    }
  }
}
