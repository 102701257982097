@use '../../assets/styles/mixins' as *;

$className: 'skip-link';

.#{$className} {
  z-index: 1;
  position: absolute;
  top: 200px;
  left: -9999px;
  transform: translateX(-50%);

  &:focus {
    left: 50%;
  }
}
