@use 'mixins' as m;

:root {
  // colors
  --color-white: #fff;
  --color-black: #000;
  --color-primary: #00a181;
  --color-primary-dark: #009175;
  --color-primary-light: #ade1d6;
  --color-primary-lighter: #edfaf8;
  --color-secondary: #929292;
  --color-secondary-dark: #444749;
  --color-secondary-light: #f5f5f5;
  --color-accent: #fd7e14;
  --color-error: #d2504b;
  --color-error-light: #fed7d5;
  --color-grey: #cfcfcf;

  // shadows
  --box-shadow: 3px 5px 10px rgb(68 74 79 / 0.1);
  --box-shadow-down: 0.25rem 0 0.75rem 0 rgb(0 0 0 / 0.15);

  // general
  --container-width: 73rem;
  --font-body: 'Open Sans', sans-serif;

  // transitions
  --easing: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  --transition-duration: 0.2s;

  // spacings
  --spacing-tiny: 0.5rem;
  --spacing-sm: 1rem;
  --spacing-md: 1.5rem;
  --spacing-lg: 2rem;
  --spacing-xl: 2.5rem;

  @include m.bp(medium) {
    --spacing-tiny: 1rem;
    --spacing-sm: 2rem;
    --spacing-md: 3rem;
    --spacing-lg: 4rem;
    --spacing-xl: 5rem;
  }

  --border-radius: 30px;
}
