@use '../../assets/styles/mixins' as *;

.rs-cc--show {
  display: block;
}

.rs-cc--hide {
  display: none !important;
}

/**
* Styles
*/

.rs-cc {
  z-index: 200;
  inset: 0;
  position: fixed;
  font-size: 13px;
  line-height: 24px;
  font-family: var(--font-body);

  @include bp(medium) {
    font-size: 15px;
  }

  &--main {
    border-bottom: 2px solid var(--color-primary);
    max-height: 100vh;
    overflow-y: auto;
  }

  &--overlay {
    height: 100%;
    background: rgb(0 0 0 / 0.4);
  }

  &__step-container {
    max-width: rem(1320px);
    padding: 24px;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 15px;

    @include bp(medium) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &.-step-2 {
      font-family: var(--font-body);
      font-size: 16px;
    }
  }

  /**
  * texts
  */

  p {
    color: var(--color-black);
    margin: 0 0 20px;
    line-height: 24px;
    font-size: 14px;
    letter-spacing: 0.78px;

    @media only screen and (min-width: 768px) {
      font-size: 16px;
      letter-spacing: 0.89px;
    }

    &.rs-cc__step-title {
      margin: 12px 0 24px;
      font-size: 22px;
      color: var(--color-primary);
      letter-spacing: 0.5px;
      line-height: 24px;
      font-weight: 700;

      @media only screen and (min-width: 768px) {
        margin: 32px 0 30px;
        font-size: 30px;
        line-height: 40px;
        letter-spacing: 0.67px;
      }
    }

    &.rs-cc__step-segment {
      font-weight: bold;
      margin: 0 0 10px;
    }
  }

  &__step-description {
    padding-right: 20px;
  }

  /**
  * Buttons
  */

  &__buttons-container {
    margin-right: 2rem;
  }

  .button {
    &::before,
    &::after {
      display: none;
    }

    &--rs-cc {
      cursor: pointer;
      border-radius: rem(4px);
      color: #fff;
      border: 0;
      padding: 10px;
      max-width: 100%;
      width: 100%;
      display: inline-block;
      font-family: var(--font-body);
      font-weight: 700;
      margin-bottom: 0;
    }

    &.rs-cc__confirm-all {
      width: auto;
      padding: 0.5rem 1rem;

      &:hover {
        background-color: var(--color-primary-dark);
      }
    }

    &--link {
      border: none;
      text-decoration: underline;
      letter-spacing: 0.78px;
      line-height: 24px;
      background: none;
      color: var(--color-white);
      margin: 0;
      padding: 0;
      text-transform: none;
      font-family: var(--font-body);
      font-size: 14px;
      box-shadow: none;
      width: auto !important;

      @media (hover: hover) {
        &:hover {
          text-decoration: none;
        }
      }
    }

    &.-rounded {
      border-radius: 16px;
      text-transform: uppercase;
    }

    &.-underlined {
      text-decoration: underline;
      text-transform: none;
      border-color: transparent;
      padding: 0;
      margin: 0;
      font-size: 14px;
      border: none;
      display: inline-block;
      background: transparent;
      color: var(--color-primary);
      box-shadow: none;
      max-width: none;
      width: auto;

      @media (hover: hover) {
        &:hover {
          text-decoration: none;
        }
      }
    }

    &.rs-cc__save-settings {
      cursor: pointer;
      border-radius: 4px;
      color: #fff;
      border: 0;
      padding: 10px 20px;
      width: auto;
      display: inline-block;
      text-transform: none;

      @media (hover: hover) {
        &:hover {
          text-decoration: none;
          color: #fff !important;
          background-color: var(--color-primary-dark);
        }
      }
    }
  }

  &__close {
    position: absolute;
    top: 1rem;
    right: 5px;
    padding: 0;
    margin: 0;
    border: 0;
    background: none !important;
    line-height: 0;
    width: 40px;
    height: 40px;
    text-align: center;
    cursor: pointer;
    box-shadow: none;

    .icon {
      fill: var(--color-primary);
      font-size: 12px;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @media only screen and (min-width: 1023px) {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  /**
  * Steps
  */

  &__step {
    background-color: var(--color-white);
    color: var(--color-black);

    &-2 {
      .rs-cc__step-container {
        padding-bottom: 50px;
      }

      .rs-cc__close {
        top: 1rem;
        transform: none;
      }
    }
  }

  /**
  * Settings
  */

  &__cookie-groups {
    margin-top: 30px;

    @media only screen and (min-width: 768px) {
      margin-top: 50px;
    }

    .group {
      position: relative;
      margin-bottom: 25px;

      @media only screen and (min-width: 768px) {
        margin-bottom: 30px;
      }

      &::before {
        content: none;
      }

      &:last-child {
        margin-bottom: 40px;

        @media only screen and (min-width: 768px) {
          margin-bottom: 50px;
        }
      }
    }
  }

  &__group-text {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 15px 0;
  }

  &__text-left {
    width: 42px;

    @media only screen and (min-width: 768px) {
      width: 60px;
    }
  }

  &__text-right {
    width: calc(100% - 42px);

    @media only screen and (min-width: 768px) {
      width: calc(100% - 60px);
    }
  }

  &__show-list-button,
  &__hide-list-button {
    position: absolute;
    right: 0;
  }

  &__group-description {
    font-size: 16px !important;
    letter-spacing: 0.32px !important;
    margin-bottom: 12px !important;
  }

  &__checkbox {
    appearance: checkbox;
  }

  /**
  * Cookies table
  */

  &__table-container {
    overflow-x: scroll;
    max-width: 768px;

    @media only screen and (min-width: 768px) {
      overflow-x: auto;
      max-width: none;
    }
  }

  &__group-table {
    margin-bottom: 20px;
    width: 100%;
    padding: 20px;
    border-spacing: 0;
    border-collapse: collapse;

    @media only screen and (min-width: 768px) {
      min-width: none;
      width: 100%;
      max-width: 792px;
    }

    tr {
      border-bottom: 1px solid var(--color-primary);
    }

    th,
    td {
      padding: 7px 36px 9px 0;
      letter-spacing: 0.44px;
      text-align: left;
      border-top: 0;
      border-bottom: 1px solid var(--color-black);
      line-height: 24px;
      font-size: 15px;
      font-family: var(--font-body);
    }

    th {
      font-weight: 700;
    }
  }
}
