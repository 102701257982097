@use 'mixins' as *;

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  overflow: hidden;
  fill: currentColor;
  stroke: currentColor;
  pointer-events: none;
}

.hide-mobile {
  display: none;

  @include bp(medium) {
    display: inline-block;
  }
}

.hidden {
  display: none !important;
}

.color-dark {
  color: var(--color-secondary-dark);
}

.color-white {
  color: var(--color-white);
}

.color-primary {
  color: var(--color-primary);
}
